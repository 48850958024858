import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import requireEspace, { EspaceProps } from '../../../../utils/requireEspace';
import { UserProps } from '../../../../utils/requireUser';
import Contacts from './index';

const PageContactsSubscribers: FC<
  PageProps & EspaceProps & UserProps
> = props => <Contacts {...props} pageContext={{ tab: 2 }} />;

export default requireEspace(PageContactsSubscribers);
